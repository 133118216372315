import * as httpCall from "../../../Utils/apis/apis";
import defaultMinervaTheme from "../../Themes/defaultMinervaTheme";

export async function getRecordByProperty(apiPayload) {
  const res = await httpCall.httpPost(
    `${process.env.API_URL}/CRMData/getRecordByProperty`,
    apiPayload
  );
  let contact_crm = "";
  if (res && res.length > 0 && res[0] && res[0].id) {
    contact_crm = res[0];
  }
  if (contact_crm) {
    localStorage.setItem("contact_crm", JSON.stringify(contact_crm));
  } else {
    localStorage.setItem("contact_crm", "{}");
  }
}

export async function getGlobalSearchPermission(user) {
  let globalSearchPermissionRes = await httpCall.httpGet(
    "/CRMData/globalSearchPermission"
  );
  if (user) {
    const response = globalSearchPermissionRes;
    return response;
  } else {
    return false;
  }
}
export async function getColourScheme() {
  let apiUrl = "/ColourScheme";
  let colourSchemeResult = await httpCall.httpGet(apiUrl);
  if ("message" in colourSchemeResult) {
    const defaultSerializedTheme = JSON.stringify(defaultMinervaTheme);
    localStorage.setItem("minervaTheme", defaultSerializedTheme);
  } else {
    const serializedTheme = JSON.stringify(colourSchemeResult);
    localStorage.setItem("minervaTheme", serializedTheme);
  }
}
export async function validSubdomainUser() {
  let apiUrl = `/common/validsubdomainuser`;
  const validSubdomainUserResponse = await httpCall.httpGet(apiUrl);
  return validSubdomainUserResponse;
}

export async function getDefaultRedirectURLForUser() {
  let apiUrl = "/common/getDefaultRedirectURLForUser";
  let redirectUrl = await httpCall.httpGet(apiUrl);
  return redirectUrl;
}

export async function isUserSuperadmin() {
  const apiUrl = "/common/role/superadmin";
  const issuperadmin = await httpCall.httpGet(apiUrl);
  localStorage.setItem("isUserSuperAdmin", issuperadmin);
}
